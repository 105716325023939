import * as tConst from "../../common/Constants";
import { pickColorAdo } from "../../utils/pickColor";

const emptyString= "";


export function PrepareTimelineDataAdo (items, viewId, assigneeFilterName = tConst.ADO_UNASSIGNED){
    
    let dataset = [];
    let _minDate = new Date(2999, 11, 31);
    let _maxDate = new Date(1970, 0, 1);

    if (items != null && items.length > 0) {
        const tempItems2 = items.filter((item) => {
            const end =  new Date(item.fields["Custom.EndIteration"])
            const status = item.fields["System.State"]
            const now = new Date() 
            const from = new Date(item.fields["Custom.StartIteration"])
            if ((viewId ? viewId : tConst.VIEW_ALL_PLANNED) === tConst.VIEW_ALL_PLANNED){
                return true;
            } else if (viewId === tConst.VIEW_ACTIVE_AND_CURRENT){
                return (end >= now || tConst.ADO_INACTIVE_STATUSES.indexOf(status) ===-1 )
            } else if (viewId === tConst.VIEW_ACTIVE){
                const a = status in tConst.ADO_INACTIVE_STATUSES;
                return (tConst.ADO_INACTIVE_STATUSES.indexOf(status) === -1)
            } else if (viewId === tConst.VIEW_CURRENT_ITERATION){
                return (end >= now && from <= now) 
            } else if (viewId === tConst.VIEW_FUTURE){
                return end >= now;
            }
        
        })

        dataset = tempItems2?.sort((a, b) => a.planningPri > b.planningPri ? 1 : -1).map((item) => {            
            let fromDate = new Date(item.fields["Custom.StartIteration"])
            let endDate =  new Date(item.fields["Custom.EndIteration"])
            if  (endDate< fromDate) {
                const tempDate = fromDate;
                fromDate = endDate;
                endDate = tempDate;
            }
            if (_minDate > fromDate) {
                _minDate = fromDate;
            };
            if (_maxDate < endDate) {
                _maxDate = endDate;
            };

            const tooltip =         "<div style='white-space: nowrap; margin: 1em;'>"
            + "["+item.id+"] "+item.fields["System.Title"]
            + "<br/>Assignee: " + item.Assignee
            + "<br/>Status: " + item.fields["System.State"] + (item.fields["Custom.PlanningStatus"]? "&nbsp / &nbsp"+ item.fields["Custom.PlanningStatus"] : "")
            + "<br/>Priority: " + (item.fields["Custom.Planningpriority"] ? item.fields["Custom.Planningpriority"] : "<Unprioritized>") // + " / " + ticket.guestimate + " guestimate"
            + "<br/>Period: " + tConst.formatDate(fromDate) + " - " + tConst.formatDate(endDate)
            + "<br/>Epic/ Initiativ: "+emptyString + "<epic>"+emptyString + " / " + item.fields["Custom.Initiative"]
            + "<br/>Type: " + item.fields["System.WorkItemType"]
            + "</div>";

            const _type = item.fields["System.WorkItemType"]
            const prefix = _type === "Epic" ? "E " : _type === "Initiative" ? "I " : _type ===  "Feature" ? "F ": _type === "Product Backlog Item" ? "P " : "";
            
            let _dimColor = false;
            if (assigneeFilterName !== tConst.ADO_UNASSIGNED){
                 _dimColor = (assigneeFilterName !== item.Assignee)
            }

            return [
                (item.fields["Custom.Initiative"] && item.fields["Custom.Initiative"] !== "") ? item.fields["Custom.Initiative"] : "..",
                prefix+"["+item.id+"] " + item.fields["System.Title"],
                tooltip,
                pickColorAdo(item.fields["System.State"], item.fields["Custom.PlanningStatus"],"", _dimColor ),
                fromDate,
                endDate
            ]
        } )  
    } 


    return {dataset, minDate: _minDate, maxDate: _maxDate}

}

